<template>
  <div class="event-details-wrapper">
    <Scrollable :style="scrollableStyle">
      <component :is="eventsComponent"
                 :statsData="statistics"
                 :resultsData="results"
                 :colors="labelColors"
                 :roundLabel="roundLabel"
                 :racers="racers">
      </component>
    </Scrollable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Scrollable } from '@nsftx/games-sdk-js';
import RacesEventsAreaA from '@/components/RacesEventsAreaA';

export default {
  name: 'EventsArea',
  components: {
    Scrollable,
    RacesEventsAreaA,
  },
  computed: {
    ...mapGetters([
      'config',
      'gameState',
      'labelColors',
      'racers',
      'translations',
    ]),
    eventsComponent() {
      return this.config.areas.eventsArea;
    },
    statistics() {
      return this.gameState.statistics;
    },
    results() {
      return this.gameState.previousResults;
    },
    roundLabel() {
      return this.translations.general_round;
    },
    scrollableStyle() {
      return {
        height: '100%',
        maxHeight: '100%',
        overflowY: 'auto',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.event-details-wrapper {
  flex: 1;
  ::v-deep .racer-label-wrapper {
    margin: 1px 0px;
  }
  ::v-deep .races-events-area-a {
    height: 100%;
    display: flex;
    flex-direction: column;
    .tabs {
      .tab-button {
        max-width: unset;
      }
    }
  }
}
</style>
